import React from "react";

import { Container } from "./styles";
import {
  DownloadApp,
  HowTo,
  Features,
  PresentationalVideo,
  Reviews
} from "./components";

const Home = () => (
  <Container>
    <DownloadApp />
    <PresentationalVideo />
    <HowTo />
    <Features />
    {/* <Reviews /> */}
  </Container>
);

export default Home;
