import React from "react";
import {
  faUserCircle,
  faFileSignature,
  faCar,
  faDollarSign
} from "@fortawesome/free-solid-svg-icons";

import { Container, SectionSeparator } from "./styles";
import Step from "./components/Step";

import iPhoneMock from "../../../../assets/images/splash-screen-mockup.png";

const HowTo = () => (
  <Container>
    <SectionSeparator />
    <div className="how-to-mock">
      <img src={iPhoneMock} height={550} alt="iPhone Mock" />
    </div>
    <div className="how-to-info">
      <h1 className="how-to-info-title">Entenda o App</h1>
      <Step
        icon={faCar}
        title="Estacione o seu veículo"
        description="Estacione o seu veículo em uma área do Estacionamento Rotativo - Estar Digital - em Curitiba."
      />
      <Step
        icon={faUserCircle}
        title="Cadastre-se no App"
        description="Preencha o cadastro. É muito rápido e fácil."
      />
      <Step
        icon={faFileSignature}
        title="Preencha os dados"
        description="Insira a placa, escolha o Setor de Estacionamento, o Tempo de Permanência e a Forma de Pagamento."
      />
      <Step
        icon={faDollarSign}
        title="Efetue o pagamento"
        description="Você pode efetuar o pagamento através de cartão de crédito, transferência ou boleto."
      />
    </div>
  </Container>
);

export default HowTo;
