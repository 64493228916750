import React from "react";
import {
  faShieldAlt,
  faMobileAlt,
  faHandHoldingUsd
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  SectionSeparator,
  FeatureSecure,
  FeatureEasyToUse,
  FeatureFairPrice
} from "./styles";

const Features = () => (
  <Container>
    <SectionSeparator />
    <h1 className="features-title">Vantagens</h1>

    <FeatureSecure
      icon={faShieldAlt}
      title="Seguro"
      description="Diversas camadas de segurança são utilizadas para proteger os seus dados."
    />

    <FeatureEasyToUse
      icon={faMobileAlt}
      title="Fácil de usar"
      description="Utilizamos uma interface extremamente amigável, intuitiva e de fácil uso."
    />

    <FeatureFairPrice
      icon={faHandHoldingUsd}
      title="Preço justo"
      description="Não cobramos quaisquer taxas adicionais, o preço para estacionar é o mesmo definido pela URBS."
    />
  </Container>
);

export default Features;
